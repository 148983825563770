import axios from "axios";
import logo from "./logo-lg.png";
import "./Table.css";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
function Blacklist(props) {


  const [isLoading, setIsLoading] = useState(true);
  const [blacklistData, setBlacklistData] = useState([]);
  const [filteredBlacklist, setFilteredBlacklist] = useState([]);

  // const [filteredBlacklist, setfilteredBlacklist] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getBlacklisted();
  }, []);



  const getBlacklisted = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get("/api/blacklists");
      setBlacklistData(data);
      setFilteredBlacklist(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const filtered = blacklistData.filter(
      ({ lastName, firstName, positionName, institutionName, reason }) =>
        (
          (lastName || "") +
          (firstName || "") +
          (positionName || "") +
          (institutionName || "") +
          (reason || "")
        )
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
    );

    setFilteredBlacklist(filtered);
    paginate(filtered);
  }, [searchTerm, blacklistData]);

  //PAGINATION
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const itemsPerPage = 20;

  const paginate = (items) => {
    const endOffset = itemOffset + itemsPerPage;
    setPaginatedItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };
  useEffect(
    () => {
      paginate(filteredBlacklist);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemOffset, itemsPerPage, blacklistData, filteredBlacklist]
  );

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % filteredBlacklist.length || 0;

    setItemOffset(newOffset);
  };

  return (
    <>
      {!isLoading && (
        <div className="container-fluid">
          <div id="nav" className="row align-items-center mb-1 mt-1 m-3">
            <div id="logo" className="col-lg-2">
              <img src={logo} className="img-fluid" alt="logo" />
            </div>
            <div className="col-1"></div>
            <div className="col-lg-6 text-center">
              <span id="be" className="text-center">
                <Typography variant="h5"> BLACKLISTED EMPLOYEES </Typography>
              </span>
            </div>
            <span className="counter pull-right"></span>
          </div>
          <div className="row ">
            <div className="col-lg-12 ">
              <div
                className=""
                style={{ height: "3px", background: "#07bc0c" }}
              ></div>
              <div
                className=""
                style={{ height: "3px", background: "#f1c40f" }}
              ></div>

              <div
                className="row mx-0 text-center text-white px-5 py-5"
                style={{ height: "140px", backgroundColor: "#3498db" }}
              >
                <div className="col">
                  <Typography variant="h7">
                    BLACKLISTED EMPLOYEES FROM RWANDA PUBLIC SERVICE
                  </Typography>
                  <div
                    id="search"
                    className="form-group float-right"
                    style={{ textAlign: "center" }}
                  >
                    <input
                      id="Ip"
                      type="text"
                      className="search form-control"
                      placeholder="search"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className=" "
                style={{ height: "3px", background: "#f1c40f" }}
              ></div>
              <div
                className=""
                style={{ height: "3px", background: "#07bc0c" }}
              ></div>

              <table className=" table table-striped table-bordered table-sm table table-hover pt-5">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">Name</th>
                    {/* <th scope="col">IDNumber</th> */}
                    <th scope="col">Gender</th>
                    <th scope="col">Position</th>
                    <th scope="col">Institution</th>
                    <th scope="col" style={{ width: "100px!important" }}>
                      Reason
                    </th>
                    <th scope="col">BlacklistedOn</th>
                    {/* <th scope="col">EndOn</th> */}
                  </tr>
                </thead>
                <tbody className="bg-rgb(245, 255, 250)">
                  {paginatedItems.map((row, index) => (
                    <tr key={index}>
                      <th scope="row">{row.no}</th>
                      <td>
                        {row.firstName} {row.lastName}
                      </td>
                      {/* <td>{row.idNumber}</td> */}
                      <td>{row.genderId}</td>
                      <td>{row.positionName}</td>
                      <td>{row.institutionName}</td>
                      <td style={{ width: "100px!important" }}>
                        {row.exitTypeName} {!!row.reason?' - '+row.reason:''}
                      </td>
                      <td>{moment(row.startOn).format("MMMM Do YYYY")}</td>
                      {/* <td>{moment(row.endOn).format("MMMM Do YYYY")}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
              {!filteredBlacklist.length && (
                <div className="alert alert-danger text-center">
                  no record found
                </div>
              )}
            </div>
          </div>
          <div className="row pb-4">
            <div
              className="d-flex justify-content-center"
              style={{ overflow: "hidden" }}
            >
              {!!paginatedItems.length &&
                blacklistData.length >= itemsPerPage && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">Next</span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={pageCount}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Blacklist;
